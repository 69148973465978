import React, { useState } from "react";
import cx from "classnames";

export default () => {
  const [clicked, setClicked] = useState(false);

  const renderButton = () => {
    return (
      <button
        className={cx(
          "menu focus:outline-none",
          clicked ? "opened absolute z-50 top-0 right-1 mt-2" : ""
        )}
        onClick={() => {
          window.scrollTo(0, 0);
          if (!clicked) {
            document.body.style.overflowY = "hidden";
          } else {
            document.body.style.overflowY = "auto";
          }
          setClicked(!clicked);
        }}
        aria-label="Main Menu"
        aria-expanded={clicked}
      >
        <svg width="60" height="60" viewBox="0 0 100 100">
          
         
        </svg>
      </button>
    );
  };

  return (
    <header className="container px-4 mx-auto py-4">
      <div className="flex flex-row justify-between items-center relative">
        <a
          href="#"
          className="logo flex flex-row items-center"
        >
          <img
            src="/images/full-logo.png"
            width="270"
            className="cursor-pointer"
            alt="logo"
          />
        </a>
        <div className="cursor-pointer">{renderButton()}</div>
      </div>
     
    </header>
  );
};
