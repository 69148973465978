import React from "react";

export default () => {
  return (
    <div className="py-8 footer-bg">
      <footer className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <div>
          
            <div className="flex flex-row items-center ">
               
            </div>
          </div>
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
          </div>
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <div className="font-Montserrat-ExtraBold uppercase">
              
            </div>
          
          </div>
        </div>
        <div className="footer-copyright text-center py-3 text-white">
     
      </div>
      </footer>
    </div>
  );
};

